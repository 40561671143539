import UsersServices from '@/services/User'
export const state = () => ({
  user: {},
  newUser: [],
  notif: {},
  dataAccount: {},
  isLoading: false,
  accounts: [],
  link3DS: '',
  createCC: false,
  profileUser: {}
})

export const mutations = {
  setUser (state, payload) {
    state.user = payload
  },
  setNewUser (state, payload) {
    state.newUser = payload
  },
  setNotif (state, payload) {
    state.notif = payload
  },
  setLink3DS (state, payload) {
    state.link3DS = payload
  },
  setAccounts (state, payload) {
    if (payload.reset) {
      state.accounts = []
    }
    state.accounts = state.accounts.concat(payload.payload)
  },
  setDataAccount (state, payload) {
    state.dataAccount = payload
  },
  setCreateCC (state, payload) {
    state.createCC = payload
  },
  setProfileUser (state, payload) {
    state.profileUser = payload
  }
}

export const actions = {
  getUser ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.getUser(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.data.message === 'success') {
            commit('setUser', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getNewUser ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.getUser(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setNewUser', response.data.data.users)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  editUser ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.editUser(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  deleteAccount ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.deleteAccount(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  changePassword ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.changePassword(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  toggleWishlist ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.toggleWishlist(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  setFCMToken ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.setFCMToken(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getNotification ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.getNotification(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setNotif', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  readAllNotification ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.readAllNotification(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  showNotification ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.showNotification(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload,
        null
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setNotif', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getHistoryPoint ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.getHistoryPoint(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getPayment ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.getPayment(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setAccounts', {
              payload: response.data.data.accounts.data,
              reset: response.data.data.accounts.current_page === 1
            })
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  createTokenCC ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      window.Xendit.card.createToken({ ...payload }, (error, response) => {
        if (error) {
          console.log(error)
          reject(error)
        } else {
          resolve(response)
        }
      })
    })
  },
  createPayment ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.createPayment(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  checkValidCC ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.checkValidCC(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  deletePayment ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.deletePayment(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  // setProfileUser
  showProfileUser ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.showProfileUser({
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload)
        .then((response) => {
          commit('setProfileUser', response.data.data.user)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export const getters = {}
