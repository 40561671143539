import ProductServices from '@/services/Product/'
import { getPageNumber } from '@/utils/helper'

export const state = () => ({
  product: {},
  mainProducts: [],
  productsRecomendation: [],
  productNotReviewed: [],
  newProducts: [],
  mostReviewed: [],
  bestSellerHomepage: [],
  bestSeller: [],
  productReviews: [],
  productReviewGallery: {},
  productWishlist: [],
  productPreSaleSession: [],
  productAffiliate: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loadMore: false
  },
  popularAffiliateProduct: [],
  productUnAffiliated: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loadMore: false
  },
  popularUnAffiliated: [],
  page: 1,
  ratingAccumulation: [
    { count: 0, progress: 0 },
    { count: 0, progress: 0 },
    { count: 0, progress: 0 },
    { count: 0, progress: 0 },
    { count: 0, progress: 0 }
  ],
  isLoading: false,
  isCreated: false,
  idProduct: '',
  loadingProduct: {},
  detailProduct: {},
  totalProducts: 0,
  shareUrlAffiliate: '',
  affiliateUserProducts: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loadMore: false,
    loading: false
  },
  affiliateUserAllProduct: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loadMore: false,
    loading: false
  },
  productBestSellingMonthly: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loadMore: false,
    loading: false
  },
  popularAffiliateMonthly: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loadMore: false,
    loading: false
  },
  deletedAffiliateItems: {},
  catalogAffiliate: {
    section: '',
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loadMore: false,
    loading: false
  }
})

export const mutations = {
  setMainProducts (state, payload) {
    state.mainProducts = payload
  },
  addMainProducts (state, payload) {
    payload.map((pay) => {
      state.mainProducts.push(pay)
    })
  },
  setProductRecomendation (state, payload) {
    state.productsRecomendation = payload
  },
  setProductNotReviewed (state, payload) {
    state.productNotReviewed = payload
  },
  setNewProducts (state, payload) {
    state.newProducts = payload
  },
  setMostReviewed (state, payload) {
    state.mostReviewed = payload
  },
  setBestSellerHomepage (state, payload) {
    state.bestSellerHomepage = payload
  },
  setBestSeller (state, payload) {
    state.bestSeller = payload
  },
  setSuccess (state, payload) {
    state.isCreated = payload
  },
  setProduct (state, payload) {
    state.product = payload
    state.idProduct = payload.id
  },
  setSelectedDetails (state, payload) {
    state.detailProduct = payload
  },
  setProductReviews (state, payload) {
    state.productReviews = payload
  },
  setProductWishlist (state, payload) {
    state.productWishlist = payload
  },
  setDataReviews (state, payload) {
    state.productReviews.reviews.data = payload.reviews.data
    state.productReviews.total_review = payload.reviews.data.length
  },
  setProductReviewGallery (state, payload) {
    state.productReviewGallery = payload
  },
  setRatingAccumulations (state, payload) {
    state.ratingAccumulation = payload
  },
  setTotalProducts (state, payload) {
    state.totalProducts = payload
  },
  setProductLoading (state, payload) {
    state.loadingProduct = {
      ...state.loadingProduct,
      [payload.type]: payload.value
    }
  },
  setProductPreSaleSession (state, payload) {
    state.productPreSaleSession = payload
  },
  setProductAffiliate (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.productAffiliate.data.length) {
        state.productAffiliate.data = data
      } else {
        const oldData = state.productAffiliate.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.productAffiliate.data = oldData.concat(data)
      }

      state.productAffiliate.pagination = { totalPage, total, nextPage }
      state.productAffiliate.paginated = paginated
    }
  },
  resetProductAffiliate (state, payload) {
    state.productAffiliate = {
      data: [],
      pagination: { totalPage: 0, total: 0 },
      paginated: true,
      loadMore: false

    }
  },
  setPopularAffiliateProduct (state, payload) {
    state.popularAffiliateProduct = payload
  },
  resetPopularAffiliateProduct (state, payload) {
    state.popularAffiliateProduct = []
  },
  setShareUrl (state, payload) {
    state.shareUrlAffiliate = payload
  },
  setProductUnAffiliated (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload
    if (paginated) {
      if (!state.productUnAffiliated.data.length) {
        state.productUnAffiliated.data = data
      } else {
        const oldData = state.productUnAffiliated.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.productUnAffiliated.data = oldData.concat(data)
      }

      state.productUnAffiliated.pagination = { totalPage, total, nextPage }
      state.productUnAffiliated.paginated = paginated
    }
  },
  resetProductUnAffiliated (state, payload) {
    state.productUnAffiliated = {
      data: [],
      pagination: { totalPage: 0, total: 0 },
      paginated: true,
      loadMore: false

    }
  },
  setPopularUnAffiliated (state, payload) {
    state.popularUnAffiliated = payload
  },
  setAffiliateUserProducts (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.affiliateUserProducts.data.length) {
        state.affiliateUserProducts.data = data
      } else {
        const oldData = state.affiliateUserProducts.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.affiliateUserProducts.data = oldData.concat(data)
      }
    } else {
      state.affiliateUserProducts.data = data
    }

    state.affiliateUserProducts.pagination = { totalPage, total, nextPage }
    state.affiliateUserProducts.paginated = paginated
  },
  // affiliateUserAllProduct
  setAffiliateUserAllProduct (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.affiliateUserAllProduct.data.length) {
        state.affiliateUserAllProduct.data = data
      } else {
        const oldData = state.affiliateUserAllProduct.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.affiliateUserAllProduct.data = oldData.concat(data)
      }
    } else {
      state.affiliateUserAllProduct.data = data
    }

    state.affiliateUserAllProduct.pagination = { totalPage, total, nextPage }
    state.affiliateUserAllProduct.paginated = paginated
  },
  setProductBestSellingMonthly (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.productBestSellingMonthly.data.length) {
        state.productBestSellingMonthly.data = data
      } else {
        const oldData = state.productBestSellingMonthly.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.productBestSellingMonthly.data = oldData.concat(data)
      }
    } else {
      state.productBestSellingMonthly.data = data
    }

    state.productBestSellingMonthly.pagination = { totalPage, total, nextPage }
    state.productBestSellingMonthly.paginated = paginated
  },
  setPopularAffiliateMonthly (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.popularAffiliateMonthly.data.length) {
        state.popularAffiliateMonthly.data = data
      } else {
        const oldData = state.popularAffiliateMonthly.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.popularAffiliateMonthly.data = oldData.concat(data)
      }
    } else {
      state.popularAffiliateMonthly.data = data
    }

    state.popularAffiliateMonthly.pagination = { totalPage, total, nextPage }
    state.popularAffiliateMonthly.paginated = paginated
  },
  setCatalogAffiliate (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.catalogAffiliate.data.length) {
        state.catalogAffiliate.data = data
      } else {
        const oldData = state.catalogAffiliate.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.catalogAffiliate.data = oldData.concat(data)
      }
    } else {
      state.catalogAffiliate.data = data
    }

    state.catalogAffiliate.pagination = { totalPage, total, nextPage }
    state.catalogAffiliate.paginated = paginated
  },
  resetCatalogAffiliate (state, payload) {
    state.catalogAffiliate = {
      section: '',
      data: [],
      pagination: { totalPage: 0, total: 0 },
      paginated: true,
      loadMore: false,
      loading: false
    }
  }
}

export const actions = {
  getProducts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getHomeProducts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getHomeProducts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getHomeProducts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data.products.current_page === 1) {
              commit('setMainProducts', response.data.data.products.data)
            } else {
              commit('addMainProducts', response.data.data.products.data)
            }
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getRecomendationProduct ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getRecomendationProduct(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setProductRecomendation', response.data.data.products.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getProductNotReviewed ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductNotReviewed(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit(
              'setProductNotReviewed',
              response.data.data.order_products.data
            )
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getTopPickProducts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getTopPickProducts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data.products.current_page === 1) {
              commit('setMainProducts', response.data.data.products.data)
            } else {
              commit('addMainProducts', response.data.data.products.data)
            }
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getNewProducts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getNewProducts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data.products.current_page === 1) {
              commit('setMainProducts', response.data.data.products.data)
            } else {
              commit('addMainProducts', response.data.data.products.data)
            }
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getMostReviewedProducts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getMostReviewedProducts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data.products.current_page === 1) {
              commit('setMainProducts', response.data.data.products.data)
            } else {
              commit('addMainProducts', response.data.data.products.data)
            }
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getBestSellerHomePage ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getBestSellerHomePage(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setBestSellerHomepage', response.data.data.categories)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getDetailProduct ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getDetailProduct(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.slug,
        payload.payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setProduct', response.data.data.product)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getMetaProduct ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getMetaProduct(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getProductReviews ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductReviews(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.id,
        payload.payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setProductReviews', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getReviewsByPage ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductReviews(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.id,
        payload.payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setDataReviews', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getProductReviewFiles ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductReviewFiles(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.id,
        payload.payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setProductReviewGallery', response.data.data.files)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getLastSeenProducts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getLastSeenProducts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data.products.current_page === 1) {
              commit('setMainProducts', response.data.data.products.data)
            } else {
              commit('addMainProducts', response.data.data.products.data)
            }
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getProductsSale ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductsSale(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getProductsEventSale ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductsEventSale(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200 && !payload.dontSave) {
            const temp = []
            response.data.data.event_sale_products.data.map((product) => {
              temp.push({
                ...product.product,
                sold_percentage: product.sold_percentage || 0,
                total_sold: product.total_sold || 0
              })
            })
            if (response.data.data.event_sale_products.current_page === 1) {
              commit('setMainProducts', temp)
            } else {
              commit('addMainProducts', temp)
            }
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getProductsPreSaleSession ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductsPreSaleSession(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setProductPreSaleSession', response.data.data.event_sale_sessions)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  getProductWishlist ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductWishlist(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setProductWishlist', response.data.data.products.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getProductAffiliate ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductAffiliate(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getPopularAffiliateProduct ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductAffiliate(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setPopularAffiliateProduct', response.data.data.affiliate_products)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getProductUnAffiliated ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductUnAffiliated(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getPopularUnAffiliated ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductUnAffiliated(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setPopularUnAffiliated', response.data.data.products)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  addProductAffiliate ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.addProductAffiliate(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          commit('setSuccess', true)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getProductCategory ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductCategory(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data.products.current_page === 1) {
              commit('setMainProducts', response.data.data.products.data)
            } else {
              commit('addMainProducts', response.data.data.products.data)
            }
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getProductSection ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductSection(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.section,
        payload.payload
      )
        .then((response) => {
          if (response.status === 200 && payload.section !== 'popular-search') {
            if (response.data.data.products.current_page === 1) {
              commit('setMainProducts', response.data.data.products.data)
            } else if (!response.data.data.products.current_page) {
                commit('addMainProducts', response.data.data.products)
              } else {
                commit('addMainProducts', response.data.data.products.data)
              }
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getProductTodaySection ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductSection(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        'today-promotion',
        payload.payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getProductBrandSection ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductSection(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        'on-sale-brand-partner',
        payload.payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getProductStoreSection ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductSection(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        'on-sale-store-partner',
        payload.payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getProductNearest ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductSection(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        'nearby-location',
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getEstimatePrice ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getEstimatePrice(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  setRatingAccumulation ({ commit }, payload) {
    commit('setRatingAccumulations', payload)
  },
  getAffiliateTopProducts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getAffiliateTopProducts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const products = response.data.data.affiliate_products
          commit('setAffiliateUserProducts', {
            paginated: payload.params.paginated,
            data: products.data,
            totalPage: products.last_page,
            total: products.total,
            nextPage: getPageNumber(products.next_page_url)
          })
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getAffiliateAllProduct ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getAffiliateAllProduct(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const products = response.data.data.affiliate_products
          commit('setAffiliateUserAllProduct', {
            paginated: payload.params.paginated,
            data: products.data,
            totalPage: products.last_page,
            total: products.total,
            nextPage: getPageNumber(products.next_page_url)
          })
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getProductBestSellingMonthly ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getProductBestSellingMonthly(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const products = response.data.data.products
          commit('setProductBestSellingMonthly', {
            paginated: payload.data.paginated,
            data: products.data,
            totalPage: products.last_page,
            total: products.total,
            nextPage: getPageNumber(products.next_page_url)
          })
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getPopularAffiliateMonthly ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getPopularAffiliateMonthly(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const products = response.data.data.products
          commit('setPopularAffiliateMonthly', {
            paginated: payload.data.paginated,
            data: products.data,
            totalPage: products.last_page,
            total: products.total,
            nextPage: getPageNumber(products.next_page_url)
          })
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getCatalogAffiliate ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.getCatalogAffiliate(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const products = response.data.data.products
          commit('setTotalProducts', payload.data.paginated ? products.total : products.length)
          commit('setCatalogAffiliate', {
            paginated: payload.data.paginated,
            data: payload.data.paginated ? products.data : products,
            totalPage: products.last_page,
            total: products.total,
            nextPage: getPageNumber(products.next_page_url)
          })
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  deleteAffiliateProduct ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ProductServices.deleteAffiliateProduct(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  }
}

export const getters = {
  // Navbar
  getTotalQtyWishlist (state) {
    return state.productWishlist.length
  }
}
